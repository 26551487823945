@use '@/styles/utils/mixins.scss' as *;

.iconCardWrapper {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;

  @include mob() {
    gap: 2.5rem;
  }

  .iconCardContentContainer {
    display: flex;
    gap: 3.75rem;
    flex-direction: column;
  }

  .iconCardContentDetails {
    max-width: 38.75rem;
    width: 100%;
  }

  .iconCardLists {
    position: relative;

    .iconCard {
      display: flex;
      gap: 0.75rem;
      align-items: flex-start;
      padding: 1.5rem 1.25rem;

      @include mob() {
        padding: 1.5rem 0rem;
      }

      .iconCardBody {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      .title {
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: bold;
        color: $dark;
      }
      .description {
        color: $dark;
      }
    }

    .desktopGrid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2.5rem;
      row-gap: 1.25rem;
      align-items: flex-start;

      @include mob() {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 1.25rem;
      }
    }
  }
}
